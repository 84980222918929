import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Last day of Metcon week`}</em></p>
    <p><strong parentName="p">{`“Nield”`}</strong></p>
    <p>{`For Time:`}</p>
    <p>{`400M Run`}</p>
    <p>{`10 Deadlifts (245/155)`}</p>
    <p>{`1 Rope Climb (15′)`}</p>
    <p>{`400M Run`}</p>
    <p>{`10 Deadlifts`}</p>
    <p>{`2 Rope Climbs`}</p>
    <p>{`400M Run`}</p>
    <p>{`10 Deadlifts`}</p>
    <p>{`3 Rope Climbs`}</p>
    <p>{`400M Run`}</p>
    <p>{`10 Deadlifts`}</p>
    <p>{`4 Rope Climbs`}</p>
    <p>{`400M Run`}</p>
    <p>{`10 Deadlifts`}</p>
    <p>{`5 Rope Climbs`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start July 1st.  Email Eric at
Fallscitystrength\\@gmail for more info. `}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      